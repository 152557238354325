import { AureliaConfiguration } from 'aurelia-configuration';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, Router, RouterConfiguration } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { AuthorizeStep } from 'infra/authorize-step';
import { EnforceLowerCaseUrls } from 'infra/enforce-lowercase-urls';
import { FetchInterceptor } from 'infra/fetch-interceptor';
import { HeadwayWidget, IHeadwayState } from 'infra/headway-widget';
import { Routes } from 'infra/routes';
import { SaberFeedback } from 'infra/saber-feedback';
import { StateApi } from 'services/state-api';
import { EventKeys } from './enums/event-keys';
import { AuthenticationProvider } from './providers/authentication-provider';
import {
    CompanyLightweight,
    EnrichedNotification,
    NotificationsApiClient,
    UserProfileApiClient,
    UserProfileSettings
} from './services/cyber-api';
// Initialize marlink-ux and its custom elements and load in bootstrap
import { defineCustomElements } from 'marlink-ux/dist/esm/loader.mjs';
import 'marlink-ux/dependencies/bootstrap/js/bootstrap';
import { LocalStorageHelper, LocalStorageKeys } from './utilities/local-storage-helper';
import moment from 'moment-timezone';

declare var gtag: any;
// Register the web components within the marlink-ux library
defineCustomElements();

@autoinject
export class App {
    public authenticated: boolean = false;
    private showLayout = false;
    private companies: CompanyLightweight[];
    public companyName: string;
    public username: string;
    private version: string;
    private timeAgoSignalerInterval: number;
    private companyNameChangedSubscription: Subscription;
    private companiesChangedSubscription: Subscription;
    private companySetOnceSubscription: Subscription;
    private cyberNotificationSubscription: Subscription;
    private cyberNotificationReadSubscription: Subscription;
    private cyberNotificationsReadSubscription: Subscription;
    private timezoneSubscription: Subscription;
    private pinnedSideMenuSubscription: Subscription;
    private unreadNotificationCount: number;
    private headwayState: IHeadwayState;
    private userProfileSettings: UserProfileSettings;

    constructor(
        private auth: AuthenticationProvider,
        private config: AureliaConfiguration,
        private ea: EventAggregator,
        private notificationsApi: NotificationsApiClient,
        private userProfileApi: UserProfileApiClient,
        private router: Router,
        private state: StateApi,
        private signaler: BindingSignaler
    ) {
        this.authenticated = this.auth.isAuthenticated();

        // this is needed for GA4 because it doesn't automatically track extra dimensions like UA does.
        const company = state.company();

        //  determine if user is 'internal' or 'external', based if the email address is in the curated list
        const emailIncludes = ['@marlink.com', '@execom', '@htecgroup.com', '@itcglobal.com', '@omniaccess.com'];
        const userType = emailIncludes.some(e => this.auth.profile?.email?.includes(e))
            ? 'internal'
            : 'external';

        if (window['gtag'])
            gtag('config', 'G-ZVBH0FDSZ8', {
                dimension1: company,
                dimension2: this.auth.profile?.sub,
                dimension3: userType
            });

        this.auth.authNotifier.on('authChange', (authState) => {
            // if user wasn't authenticated before, reload the app entirely
            if (this.authenticated === false && authState.authenticated)
                this.auth.reload();

            this.authenticated = authState.authenticated;
        });

        this.showLayout = this.authenticated
            && !window.location.pathname.startsWith('/api-docs');
    }

    private activate(): void {
        // Configure the fetch interceptor
        FetchInterceptor.configure(this.auth, this.router);

        this.version = this.config.get('version');
    }

    private async attached(): Promise<void> {
        if (!this.auth.isAuthenticated()) return;

        this.checkRequiredSignOut();
        this.setUsername();

        // Subscribe to companies state change (triggered by AuthStep)
        this.companyNameChangedSubscription = this.ea.subscribe(EventKeys.onCompanyNameChanged, () => this.onCompanyNameChanged());
        this.companiesChangedSubscription = this.ea.subscribe(EventKeys.onCompaniesChanged, () => this.onCompaniesChanged());
        this.companySetOnceSubscription = this.ea.subscribeOnce(EventKeys.onCompanySet, () => {
            this.updateNotificationCount();
            this.getUserProfileSettings().then(() => this.setTimezone(undefined));
        });

        this.timezoneSubscription = this.ea.subscribe(EventKeys.onTimeZoneChanged, (tz: string) => this.setTimezone(tz));
        this.pinnedSideMenuSubscription = this.ea.subscribe(EventKeys.onPinSideMenuChanged, () => this.togglePinSideMenu());

        // Initialise the timeAgo signaler interval to update timeAgo value conversions
        this.addTimeAgoSignalerInterval();

        // Subscribe to incoming cyber notifications
        this.cyberNotificationSubscription = this.ea.subscribe(EventKeys.onCyberNotificationReceived,
            async (n: EnrichedNotification) => this.updateNotificationCount());

        // Subscribe to cyber notification read events
        this.cyberNotificationReadSubscription = this.ea.subscribe(EventKeys.onCyberNotificationRead,
            async (n: EnrichedNotification) => this.updateNotificationCount());
        this.cyberNotificationsReadSubscription = this.ea.subscribe(EventKeys.onCyberNotificationsRead,
            async (n: EnrichedNotification) => this.updateNotificationCount());

        // Widgets in the layout can only be initialized when the layout is shown, otherwise their elements would be missing
        if (this.showLayout) {
            // Enable feedback widget
            SaberFeedback.initialise();

            // Enable cyber news updates
            this.headwayState = await HeadwayWidget.initialise(this.config.get('headway.accountId'));
        }
    }

    private async updateNotificationCount(): Promise<void> {
        this.unreadNotificationCount = await this.notificationsApi.count(this.state.company());
    }

    private detached(): void {
        this.companyNameChangedSubscription.dispose();
        this.companiesChangedSubscription.dispose();
        this.companySetOnceSubscription.dispose();
        this.cyberNotificationSubscription.dispose();
        this.cyberNotificationReadSubscription.dispose();
        this.cyberNotificationsReadSubscription.dispose();
        this.timezoneSubscription.dispose();
        this.pinnedSideMenuSubscription.dispose();

        // Clean up the earlier created timeAgo signal interval
        window.clearInterval(this.timeAgoSignalerInterval);
    }

    private configureRouter(config: RouterConfiguration, router: Router): void {
        config.title = 'Marlink CyberGuard';
        config.options.pushState = true;
        config.addPreActivateStep(EnforceLowerCaseUrls);
        config.addAuthorizeStep(AuthorizeStep);
        config.map(Routes.maps(this.config));
        config.mapUnknownRoutes(Routes.unknownRoute);
    }

    private navigateToKnowledgeBase(): void {
        this.router.navigateToRoute('docs', { path1: 'home' });
    }

    private checkRequiredSignOut(): void {
        const lastSignOff = moment(LocalStorageHelper.get(LocalStorageKeys.LastSignOff));
        const initialRequiredSignOutDate = moment(this.config.get('requiredSignOutDate'));
        // requiredSignOutDate needs a fallback in case it's in the future, because then it would cause an infinite login loop
        const requiredSignOutDate =
            initialRequiredSignOutDate.isBefore(moment.utc()) ?
                initialRequiredSignOutDate :
                moment.utc().subtract(30, 'minutes');
        const needSignOut = !lastSignOff.isValid() || lastSignOff.isBefore(requiredSignOutDate);

        if (needSignOut) this.auth.logout();
    }

    private setUsername(): void {
        if (this.auth.profile)
            this.username =
                // The user's name
                this.auth.profile.name
                // The user's nickname, usually the first part of the email address
                || this.auth.profile.nickname
                // The user's email address
                || this.auth.profile.email;
    }

    private async getUserProfileSettings(): Promise<void> {
        this.userProfileSettings = await this.userProfileApi.getSettings(this.state.company());
    }

    private async togglePinSideMenu(): Promise<void> {
        this.userProfileSettings.pinSideMenu = !this.userProfileSettings.pinSideMenu;
        await this.userProfileApi.updateSettings(this.state.company(), this.userProfileSettings);
    }

    private async setTimezone(value: string | undefined): Promise<void> {
        let timeZone = '';
        if (value !== undefined) {
            timeZone = value;
            // update userProfile in database
            this.userProfileSettings.timeZone = timeZone;
            await this.userProfileApi.updateSettings(this.state.company(), this.userProfileSettings);
        } else {
            timeZone = this.userProfileSettings.timeZone;
        }

        // Set the timezone, if not set, use the local timezone
        moment.tz.setDefault(timeZone);
    }

    public onCompanyNameChanged(): void {
        this.companyName = this.state.companyName() || 'unknown';
    }

    public onCompaniesChanged(): void {
        this.companies = this.state.companies();
    }

    /** Programmatically triggers changelog to show. */
    private showChangelog(): void {
        HeadwayWidget.show();
    }

    /**
     * Creates the timeAgo signal interval that emits the 'time-ago-signal' signal every 60 seconds (60000ms)
     * This signal is used to periodically update any value conversions of the TimeAgoValueConverter so the timeAgo
     * value stays up to date (e.g. 'seconds ago' becomes 'a minute ago' after a minute because time has passed relative
     * to the source date, and thus the converted value needs to be recalculated)
     */
    private addTimeAgoSignalerInterval(): void {
        this.timeAgoSignalerInterval = window.setInterval(() => this.signaler.signal('time-ago-signal'), 60000);
    }
}
